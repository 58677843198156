import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import Hero from '../components/layout/Hero';
import ThreeColumn from '../components/layout/ThreeColumn';

interface IConnectsProps {
  data: {
    prismic: {
      allConnects: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              title: RichTextBlock[];
              content_title: RichTextBlock[];
              header_content: RichTextBlock[];
              section_header: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
              body: [
                {
                  primary: {
                    section_text: {
                      text: string;
                    };
                    section_sub_header: {
                      text: string;
                    };
                    section_header: {
                      text: string;
                    };
                  };
                },
              ];
            };
          },
        ];
      };
    };
  };
}

const Connect: React.FunctionComponent<IConnectsProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allConnects.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const slug = uid === 'home' ? '/' : `/${uid}/`;
  const title = doc.node.title;
  const content_title = doc.node.content_title;

  if (!doc) {
    return (
      <LayoutFrame currPath={slug}>
        <Helmet>
          <title>{RichText.asText(content_title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(content_title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const { header_content, section_header } = doc.node;

  const content = doc.node.body;
  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);

  return (
    <LayoutFrame currPath={slug}>
      <Helmet>
        <title>{RichText.asText(content_title)}</title>
      </Helmet>
      <Hero
        title={content_title}
        subtitle={header_content}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Hero>
      <ThreeColumn section_header={section_header} content={content}></ThreeColumn>
    </LayoutFrame>
  );
};

export default Connect;

export const query = graphql`
  query ConnectQuery {
    prismic {
      allConnects {
        edges {
          node {
            _meta {
              uid
            }
            menu_order
            primary_page_color
            page_font_color
            title
            content_title
            header_content
            section_header
            body {
              ... on PRISMIC_ConnectBodyText {
                primary {
                  section_text
                  section_sub_header
                  section_header
                }
              }
            }
          }
        }
      }
    }
  }
`;
