import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      textAlign: 'left',
      marginBottom: `${spacing(2)}px`,
    },
  });

export default styles;
