import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  Divider,
  Grid,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';

import styles from './styles';

interface IThreeColumnProps extends WithStyles<typeof styles> {
  section_header?: RichTextBlock[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content?: Array<any>;
}

const ThreeColumn: React.FunctionComponent<IThreeColumnProps> = ({
  classes,
  section_header,
  content,
}) => {
  return (
    <Paper>
      <Typography variant="h2" padding={{ top: 6, bottom: 4 }}>
        {RichText.asText(section_header)}
      </Typography>
      <Grid container constrained margin={{ x: 'auto' }}>
        {content.map((item, i) => {
          const section_header = item.primary.section_header;
          const section_sub_header = item.primary.section_sub_header;
          const section_text = item.primary.section_text;
          return (
            <Grid item xs={12} md={4} lg={4} padding={2} key={i}>
              <Typography margin={{ bottom: 2 }} variant="h3" className="lowercase">
                {RichText.asText(section_header)}
              </Typography>
              <Divider margin={{ bottom: 2 }} />
              <Typography variant="h4" margin={{ bottom: 2 }} className="small">
                {RichText.asText(section_sub_header)}
              </Typography>
              <div className={classes.section}>{RichText.render(section_text)}</div>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ThreeColumn);
